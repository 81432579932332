import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../../components/Layout';
import SEO from '../../components/Seo';

import TextSection from '../../styles/textSectionStyle';

const StyledArticle = styled.article`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	color: ${(props) => props.theme.colors.lightest};

	::before {
		content: '';
		background-color: ${(props) => props.theme.colors.overlay};
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0.5;
		z-index: 0;
	}

	h1 {
		font-family: ${(props) => props.theme.fonts.serif};
		font-weight: ${(props) => props.theme.fonts.weights.light};
		font-size: 3.2rem;
		text-transform: none;
		margin-bottom: 2rem;
		letter-spacing: normal;
	}

	p {
		font-size: 1.8rem;
		line-height: 2;
		max-width: 380px;
		margin: 0 auto 4rem;
	}

	.content-column {
		flex-basis: 1000px;
	}
`;

const ThanksPage = () => {
	const data = useStaticQuery(graphql`
		query BackgroundImageQuery {
			file(name: { eq: "pnr-contact-01" }) {
				childImageSharp {
					fluid(maxWidth: 1920, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const { file: backgroundImage } = data;

	return (
		<Layout background="dark" page="thanks">
			<SEO title={`Thank You`} />
			<StyledArticle
				style={{
					backgroundImage: `url(${
						!!backgroundImage.childImageSharp
							? backgroundImage.childImageSharp.fluid.src
							: backgroundImage
					})`,
				}}
			>
				<TextSection>
					<div className="container">
						<div className="columns">
							<div className="column has-text-centered content-column">
								<h1>Thank you for contacting us.</h1>
								<p>
									One of our team members will get back to you as soon as
									possible.
								</p>
								<Link to="/" className="button button-2">
									Return Home
								</Link>
							</div>
						</div>
					</div>
				</TextSection>
			</StyledArticle>
		</Layout>
	);
};

export default ThanksPage;
